// import React, { useState, useEffect } from 'react';
// import Slider from 'react-slick';
// import styled from 'styled-components';
// import ReactPlayer from 'react-player';
// import axios from 'axios';

// const Container = styled.div`
//   position: relative;
//   width: 100%; 
//   height: 100vh; 
//   overflow: hidden;
// `;

// const PlayerContainer = styled.div`
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
// `;

// const Content = styled.div`
//   position: absolute;
//   top: 20px;
//   left: 20px;
//   z-index: 1;
//   color: white;
//   max-width: 300px; 
// `;

// const GradientBackground = styled.div`
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background: linear-gradient(to right, #221f1f, #000000); 
//   opacity: 0.1;
// `;

// // const TextContainer = styled.div`
// //   position: absolute;
// //   top: 50%;
// //   left: 50%;
// //   transform: translate(-50%, -50%);
// //   z-index: 2;
// //   max-width: 600px;
// //   padding: 20px;
// //   color: white;
// // `;

// const SlideshowContainer = styled.div`
//   position: absolute;
//   bottom: 20px;
//   right: 60px;
//   width: 50%; 
//   max-width: 400px; 
// `;

// const SlideImage = styled.img`
//   width: 100%;
//   height: auto;
//   border-radius: 5px;
//   margin: 0 10px; 
//   cursor: pointer;
//   transition: transform 0.2s ease-in-out;

//   &:hover {
//     transform: scale(1.05);
//   }
// `;

// const settings = {
//   dots: true,
//   infinite: true,
//   speed: 500,
//   slidesToShow: 3, // Show 3 slides at a time
//   slidesToScroll: 1, // Scroll 1 slide at a time
//   arrows: false, // Hide navigation arrows
//   responsive: [
//     {
//       breakpoint: 1024,
//       settings: {
//         slidesToShow: 1,
//         slidesToScroll: 1,
//         infinite: true,
//         dots: true
//       }
//     },
//     {
//       breakpoint: 600,
//       settings: {
//         slidesToShow: 1,
//         slidesToScroll: 1,
//         initialSlide: 1,
//         dots: true
//       }
//     }
//   ]
// };

// const App = () => {
//   const [movie, setMovie] = useState(null);

//   useEffect(() => {
//     const fetchRandomMovie = async () => {
//       try {
//         const response = await axios.get(`https://api.themoviedb.org/3/movie/popular?api_key=f6d36519794929adbee7ea8ae79ede45&language=en-US&page=1`);
//         const movies = response.data.results;
//         const randomIndex = Math.floor(Math.random() * movies.length);
//         const randomMovie = movies[randomIndex];

//         const videoResponse = await axios.get(`https://api.themoviedb.org/3/movie/${randomMovie.id}/videos?api_key=f6d36519794929adbee7ea8ae79ede45&language=en-US`);
//         const videos = videoResponse.data.results;
//         const trailer = videos.find(video => video.type === 'Trailer');

//         if (trailer) {
//           randomMovie.video_key = trailer.key;
//         }

//         setMovie(randomMovie);
//       } catch (error) {
//         console.error('Error fetching movie data', error);
//       }
//     };

//     fetchRandomMovie();
//   }, []);

//   if (!movie) return <div>Loading...</div>;

//   const trailerUrl = `https://www.youtube.com/watch?v=${movie.video_key}`;

//   return (
//     <Container>
//       <PlayerContainer>
//         <ReactPlayer
//           url={trailerUrl}
//           width="100%"
//           height="100%"
//           playing
//           controls={true}
//           muted
//           loop
//         />
//       </PlayerContainer>
//       <GradientBackground />
//       <Content>
//         <h2>Go ahead, stream free</h2>
//         <p>With Plex you can watch over 20,000 free movies and shows, plus Live TV on almost any device. What are you waiting for?</p>
//       </Content>
//       {/* <TextContainer>
//         <h1 style={{ textAlign: 'center' }}>Netflix</h1>
//       </TextContainer> */}
//       <SlideshowContainer>
//         <Slider {...settings}>
//           {movie && (
//             <div key={movie.id}>
//               <SlideImage src={`https://image.tmdb.org/t/p/w200${movie.poster_path}`} alt={movie.title} />
//             </div>
//           )}
//         </Slider>
//       </SlideshowContainer>
//     </Container>
//   );
// };

// export default App;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import axios from 'axios';

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

const PlayerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transform: scale(1.9); /* Adjust scale factor as needed to zoom in */
`;
const Content = styled.div`
  position: absolute;
  bottom: 20px; /* Position it at the bottom */
  left: 20px;
  z-index: 1;
  color: white;
  max-width: 300px; /* Adjust as needed */
`;

const GradientBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 40%; /* Width of the gradient */
  background: rgb(253,217,29);
background: -moz-linear-gradient(90deg, rgba(253,217,29,1) 70%, rgba(253,217,29,1) 80%, rgba(252,176,69,0) 100%);
background: -webkit-linear-gradient(90deg, rgba(253,217,29,1) 70%, rgba(253,217,29,1) 80%, rgba(252,176,69,0) 100%);
background: linear-gradient(90deg, rgba(253,217,29,1) 70%, rgba(253,217,29,1) 80%, rgba(252,176,69,0) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fdd91d",endColorstr="#fcb045",GradientType=1);
  opacity: 0.8; /* Adjust opacity as needed */
`;

// const TextContainer = styled.div`
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   z-index: 2;
//   max-width: 600px;
//   padding: 20px;
//   color: white;
// `;

const App = () => {
  const [movie, setMovie] = useState(null);

  useEffect(() => {
    const fetchRandomMovie = async () => {
      try {
        const response = await axios.get(`https://api.themoviedb.org/3/movie/popular?api_key=f6d36519794929adbee7ea8ae79ede45&language=en-US&page=1`);
        const movies = response.data.results;
        const randomIndex = Math.floor(Math.random() * movies.length);
        const randomMovie = movies[randomIndex];

        const detailsResponse = await axios.get(`https://api.themoviedb.org/3/movie/${randomMovie.id}?api_key=f6d36519794929adbee7ea8ae79ede45&language=en-US`);
        const movieDetails = detailsResponse.data;

        const videoResponse = await axios.get(`https://api.themoviedb.org/3/movie/${randomMovie.id}/videos?api_key=f6d36519794929adbee7ea8ae79ede45&language=en-US`);
        const videos = videoResponse.data.results;
        const trailer = videos.find(video => video.type === 'Trailer');

        if (trailer) {
          movieDetails.video_key = trailer.key;
        }

        setMovie(movieDetails);
      } catch (error) {
        console.error('Error fetching movie data', error);
      }
    };

    fetchRandomMovie();
  }, []);

  if (!movie) return <div>Loading...</div>;

  const trailerUrl = `https://www.youtube.com/watch?v=${movie.video_key}`;

  return (
    <Container>
      <PlayerContainer>
        <ReactPlayer
          url={trailerUrl}
          width="100%"
          height="100%"
          playing
          controls={true}
          muted
          loop
        />
      </PlayerContainer>
      <GradientBackground />
      <Content>
        <h2>Go ahead, stream free</h2>
        <p>With Slix you can watch over 20,000 free movies and shows, plus Live TV on almost any device. What are you waiting for?</p>
      </Content>

    </Container>
  );
};

export default App;
