import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Import Routes and Route
import '@fortawesome/fontawesome-free/css/all.min.css';
import './App.css';
import Home from './pages/Homepage/Home';
// import MovieChatbot from './components/chatbot/chatbot'; 
import Movies from './pages/Movies/MoviesPage'; 
import Actors from './pages/Actors/Actors'; 
import Footer from './components/Footer/Footer'; 
import Navbar from './components/Navbar/Navbar';
import MovieDetails from './pages/Movies/MovieDetails';
import ActorDetails from './pages/Actors/ActorDetails';
import GenreDropdown from './components/Navbar/GenreDropdown';
import SearchPage from './pages/SearchPage/SearchPage';


function App() {
  return (
    <Router>
      <Navbar /> 
      <div className="App"> 
        <Routes>
          <Route path="/" element={<div><Home /> <Movies /> <Actors /></div>} />
          <Route path="/genre/:id" element={<Movies />} />
          <Route path="/movies" element={<Movies />} /> 
          <Route path="/actors" element={<Actors />} /> 
          <Route path="/movie/:id" element={<MovieDetails />} />
          <Route path="/person/:id" element={<ActorDetails />} />
          <Route path="/search/" element={<SearchPage />} /> 
        </Routes>
        {/* <MovieChatbot /> */}
      </div>
      <Footer />
    </Router>
  );
}

export default App;
