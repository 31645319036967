import React, { useEffect, useState } from 'react';
import { fetchPopularActors } from '../../utils/api';
import { Link } from 'react-router-dom';

const ActorsPage = () => {
  const [actors, setActors] = useState([]);

  useEffect(() => {
    const getPopularActors = async () => {
      const actors = await fetchPopularActors();
      setActors(actors);
    };

    getPopularActors();
  }, []);

  return (
    <div>
      <h1>Popular Actors</h1>
      <div className="actors-list">
        {actors.map((actor) => (
          <Link key={actor.id} to={`/actor/${actor.id}`}>
            <img src={`https://image.tmdb.org/t/p/w500${actor.profile_path}`} alt={actor.name} />
            <h2>{actor.name}</h2>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default ActorsPage;
