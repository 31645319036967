import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./actorDetails.css";

const ActorDetails = () => {
  const { id } = useParams();
  const [actorDetails, setActorDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [backgroundImages, setBackgroundImages] = useState([]);
  const [currentBackgroundIndex, setCurrentBackgroundIndex] = useState(0);
  const [showFullBio, setShowFullBio] = useState(false); 

  useEffect(() => {
    const fetchActorDetails = async () => {
      try {
        const response = await axios.get(
          `https://api.themoviedb.org/3/person/${id}?api_key=f6d36519794929adbee7ea8ae79ede45`
        );
        setActorDetails(response.data);

        const imagesResponse = await axios.get(
          `https://api.themoviedb.org/3/person/${id}/tagged_images?api_key=f6d36519794929adbee7ea8ae79ede45`
        );
        setBackgroundImages(imagesResponse.data.results);
      } catch (error) {
        console.error(`Error fetching actor data:`, error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchActorDetails();
  }, [id]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBackgroundIndex((prevIndex) =>
        prevIndex + 1 >= backgroundImages.length ? 0 : prevIndex + 1
      );
    }, 5000);

    return () => clearInterval(interval);
  }, [backgroundImages.length]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading actor details.</div>;

  const birthday = new Date(actorDetails.birthday).toLocaleDateString("en-GB", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  const backgroundImageUrl =
    backgroundImages.length > 0
      ? `https://image.tmdb.org/t/p/original${backgroundImages[currentBackgroundIndex].media.backdrop_path}`
      : "";

  const trimmedBio = actorDetails.biography.split(" ").slice(0, 100).join(" ");

  return (
    <div
      className="actor-container"
      style={{ backgroundImage: `url(${backgroundImageUrl})` }}
    >
      <div className="mainactor">
        <div><img className="myactorimage" src={`https://image.tmdb.org/t/p/original${actorDetails.profile_path}`} alt={actorDetails.name} /></div>
        <div className="actorinfo">
          <h1>{actorDetails.name}</h1>
          <p>
            <h3>Biography</h3>{" "}
            {showFullBio ? actorDetails.biography : trimmedBio}
            {!showFullBio && actorDetails.biography.split(" ").length > 15} ...<br/><button onClick={() => setShowFullBio(true)}>See More</button>
          </p>
          <p>
            <strong>Birthday:</strong> {birthday}
          </p>
          <p>
            <strong>Known For:</strong> {actorDetails.known_for_department}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ActorDetails;
